import { styled } from "@mui/material";
import { breakpoint } from "../../../../components/styled-components/Breakpoints/breakpoints";

export const EmailInput = styled("input")<{ showTypingAnimation: boolean }>`
  width: 100%;
  height: 100%;
  background-color: #2c2c2c;
  border: 1px solid #ffb310;
  border-radius: 24px;
  padding: 0 35% 0 20px;
  color: #f6f6f6;
  font-family: Helvetica;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  outline: none;

  &::placeholder {
    color: #595959;
    opacity: 1;
  }

  ${breakpoint(
    "xlarge+",
    `
      font-size: 16px;
      padding: 0 32% 0 24px;
    `
  )}

  ${breakpoint(
    "large",
    `
      font-size: 14px;
      padding: 0 33% 0 20px;
    `
  )}

  ${breakpoint(
    "medium",
    `
      font-size: 13px;
      padding: 0 34% 0 16px;
    `
  )}

  ${breakpoint(
    "small",
    `
      font-size: 12px;
      padding: 0 35% 0 14px;
    `
  )}

  ${breakpoint(
    "xsmall",
    `
      font-size: 11px;
      padding: 0 36% 0 12px;
      border-radius: 18px;
    `
  )}
`;

export const EmailContainer = styled("div")`
  position: relative;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;

  ${breakpoint(
    "small-",
    `
      height: 40px;
    `
  )}
  ${breakpoint(
    "xsmall",
    `
      height: 36px;
    `
  )}
`;

export const EmailForm = styled("form")`
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ErrorText = styled("div")`
  padding: 0 20px;
  font-family: Helvetica;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  color: red;

  ${breakpoint(
    "medium-",
    `
      font-size: 12px;
      padding: 0 14px;
    `
  )}

  ${breakpoint(
    "small-",
    `
      font-size: 11px;
      padding: 0 12px;
    `
  )}
`;

export const GetStartedButton = styled("button")<{ isSubmitted: boolean }>`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  width: 30%;
  height: calc(100% - 8px);
  border-radius: 20px;
  border: none;
  background-color: #ffb310;
  color: black;
  font-family: Helvetica;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    color: #ffb310;
    background-color: black;
    border: 1px solid #ffb310;
  }

  ${breakpoint(
    "xlarge+",
    `
      font-size: 14px;
      width: 28%;
    `
  )}

  ${breakpoint(
    "large",
    `
      font-size: 13px;
      width: 29%;
    `
  )}

  ${breakpoint(
    "medium",
    `
      font-size: 12px;
      width: 30%;
    `
  )}

  ${breakpoint(
    "small",
    `
      font-size: 11px;
      width: 31%;
      border-radius: 16px;
    `
  )}

  ${breakpoint(
    "xsmall",
    `
      font-size: 10px;
      width: 32%;
      border-radius: 14px;
      height: calc(100% - 6px);
    `
  )}

  ${({ isSubmitted }) =>
    isSubmitted &&
    `
    color: #ffb310;
    background-color: black;
    border: 1px solid #ffb310;
    `}
`;

export const SubHeaderText = styled("div")`
  color: #595959;
  font-family: Helvetica;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  padding: 0 20px;

  ${breakpoint(
    "medium-",
    `
      font-size: 12px;
      padding: 0 14px;
    `
  )}

  ${breakpoint(
    "small-",
    `
      font-size: 7px;
      padding: 0 12px;
    `
  )}


  a {
    color: #ffb310;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
